.markdown {
  display: flex;
  justify-content: center;
}

.markdown > div {
  max-width: calc(100vw - 4rem);
  width: 100%;
}

.markdown img,
.markdown iframe {
  display: block;
  margin: auto;
  max-height: 100%;
  max-width: 100%;
}

.markdown hr {
  margin: 2rem 0;
}

/* .markdown[intersecting='false'] {
  height: 50vh;
}

.markdown[intersecting='true'] {
  min-height: 50vh;
} */

.markdown a {
  text-decoration: underline;
}

.markdown p > img,
.markdown iframe {
  margin: 4rem auto;
}

.markdown h2:not(:first-of-type),
.markdown h3:not(:first-of-type) {
  margin-top: 5rem;
}

.markdown li:not(:first-of-type) {
  margin-top: 0.5rem;
}

.markdown p.background {
  padding: 1rem;
  background: var(--page-color-background-color);
  border-radius: var(--border-radius-large);
}

.markdown p.background img {
  margin: 0 auto;
  border-radius: var(--border-radius-large);
}

.markdown .img-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;

  position: relative;
  height: 500px;
  max-height: 50vh;
  margin: 2rem 0;

  cursor: pointer;
}

.markdown .img-wrapper .material-icons {
  position: absolute;
  bottom: 0rem;
  left: 0rem;
  margin: 0;
}

.img-wrapper img {
  max-width: 100%;
}

.markdown .image-to-load:not([data-loaded]) {
  height: 500px;
}

/* 
.markdown img[alt]:after {  
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #fff;
  font-family: 'Helvetica';
  font-weight: 300;
  line-height: 2;  
  text-align: center;
  content: attr(alt);
} */
