.drawer-contents {
  height: 100vh;
  margin-bottom: -4rem;
  overflow-y: auto;
}

.drawer-contents .mdc-drawer__header {
  height: 8rem;
  /* background: var(--mdc-theme-primary);
  color: var(--mdc-theme-secondary-text); */
}

.drawer-contents .mdc-list-item {
  margin: 0.25rem 8px !important;
  padding: 0 !important;
}

.drawer-contents [disabled] * {
  cursor: default;
}

.drawer-contents [disabled] .mdc-list-item__text {
  background: var(--mdc-theme-secondary-light);
  color: var(--mdc-theme-secondary-text);
  width: calc(100% - 1rem);
}

.drawer-contents .drawer-header .material-icons {
  position: absolute;
  top: 1rem;
  font-size: 3rem;
}

.drawer-contents .drawer-header {
  position: relative;
}

.drawer-contents .drawer-header img {
  border-radius: 50%;
  height: 3rem;
  width: 3rem;
}

.drawer-contents .drawer-header .display-name {
  position: absolute;
  bottom: -7rem;
}

.drawer-contents .mdc-list {
  padding-top: 1rem;
}

.drawer-contents .mdc-drawer__content {
  height: calc(100% - 10rem);
}

.drawer-contents .mdc-list-item__text {
  padding: 0.25rem 0.5rem;
}

.drawer-contents .mdc-list-item__text,
.drawer-contents .mdc-list-item__text a {
  display: flex;
  align-items: center;
  flex: 1;

  margin: 0.25rem 0;
  border-radius: var(--border-radius-large);
}

.drawer-contents .material-icons:not(:last-child) {
  margin-right: 1rem;
}

.drawer-contents a {
  width: 100%;
}

.drawer-contents h4 {
  margin: 1rem 1rem 0.5rem;
  font-size: 14px;
  font-weight: 400;
  color: var(--color-text-light-gray);
}

.drawer-contents .full-width {
  width: 100%;
}

.drawer-contents .messages .material-icons {
  width: 24px;
}

.drawer-contents .notification-count-bubble {
  position: absolute;
  top: 50%;
  right: 20px;
  margin-top: -15px;
}

.drawer-contents .active[disabled] .notification-count-bubble .material-icons {
  color: var(--mdc-theme-secondary-text);
}

.drawer-contents .active[disabled] .notification-count-bubble span {
  color: var(--mdc-theme-secondary);
}
