.app-shell {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.app-shell .content {
  display: flex;
  flex-grow: 1;
  align-items: stretch;

  position: relative;
}

.app-shell .main-wrapper {
  width: 100%;
}

.app-shell main {
  flex: 1;
  justify-content: center;
  max-width: 100vw;
  min-height: calc(100vh - 7.5rem);
  padding-top: 4rem;
}

.app-shell .permanent-drawer {
  position: sticky;
  top: 0;
  height: calc(100vh - 0rem);
  transition: margin-left var(--drawer-speed) linear;
  z-index: 4;
}

.app-shell .permanent-drawer:focus-within {
  margin-left: 0;
}

@media all and (max-width: 1024px) {
  .app-shell.not-landing-page .permanent-drawer {
    margin-left: calc(-1 * var(--drawer-width));
  }
}

@media (min-width: 1025px) {
  .app-shell.not-landing-page main {
    max-width: calc(100vw - var(--drawer-width));
  }

  .app-shell.not-landing-page .mdc-top-app-bar {
    left: 255px;
  }
  
  .app-shell.landing-page .mdc-top-app-bar {
    left: 4rem;
  }
}
