.footer {
  border-top: 1px solid var(--color-border);
  padding: 1rem;
}

.footer ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;

  list-style: none;
  padding: 0;
  margin: 0;
  font-size: 14px;
}
