.permanent-drawer {
  /* position: sticky; */
}

.permanent-drawer .mdc-drawer__content {
  overflow: hidden;
}

.mdc-drawer--permanent {
  min-height: 100%;
}
