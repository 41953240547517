#primary-app-bar .flex {
  flex: 1;
}

#primary-app-bar .offline {
  position: fixed;
  bottom: 1rem;
  right: 1rem;

  display: flex;
  align-items: center;

  background: var(--color-offline);
  color: var(--color-text-light);
  text-transform: uppercase;

  margin-left: 1rem;
  padding: 0.5rem 1rem;
  border-radius: var(--border-radius-large);
}

#primary-app-bar .mdc-top-app-bar__row:before {
  content: '';
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: -10rem;

  background: var(--color-overlay-background);
}

#primary-app-bar .menu-icon-wrapper {
  margin-left: var(--menu-icon-width);
  transition: margin-left var(--drawer-speed) linear;
}

#primary-app-bar .mdc-top-app-bar__title img {
  position: absolute;
  top: 10px;
  max-height: 46px;
  max-width: calc(100vw - 8rem);
}

@media all and (max-width: 1024px) {
  #primary-app-bar .menu-icon-wrapper {
    margin-left: 0;
  }
}
